import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cut: false,
    assad: false,
  },
  getters: {
  },
  mutations: {
    asdwe(state, key) {
      state.cut = key;
    },
    // 移动端页面头部菜单
    assadTrans(state, key) {
      state.assad = key;
    }
  },
  actions: {
  },
  modules: {
  }
})
